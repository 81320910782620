import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Modal from '../components/modal.jsx'
import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'
import CacheBuster from 'react-cache-buster';
import Admin from './admin.jsx'
import Schueler from './schueler.jsx';
import {stringToHML} from './generalfunctions.jsx'
import Unterrichtsanfrage from './unterrichtsanfrage.jsx';

class Hauptseite extends Component{

    constructor(props){

        super(props)
        this.state=(
            {              
                activeUser: null,
                language:'de',
                activePage:'home',
                builddate:'',
                server:null,
                isLoggedIn:false,
                isAdmin:0,
                strings:[],
                loginname:'',
                errortext:'',
                email:'',
                passwort:'',
                password1:'',
                password2:'',
                forgotemail:'',
                sentEmail: false,
                repertoire:[],
                anfrage_name:'',
                anfrage_email:'',
                anfrage_text:'',
                passwortvergessen:false,
                schuelerFilter:'aktiv',
                schueler:[],
                filteredSchueler:[],
                activeSchueler: null,
                activeAbo: null,
                adminPage:1,
                gespeichert:'',
                nav_abo_modus:1,
                kursthemen:[],
                unterrichtsorte:[],
                lektionenpreise:null,
                lektionen:[],
                activeLektion:null,
                schueler_lektionen:[],
                schuelerAbos:[],
                activeSchuelerAbo: null,
                angebotSchuelerAbo: null,
                zahlungsBedingungenGelesen:false,
                file1:null,
                file2:null,
                file3:null,
                pdfs: [],
                
            }
        )
       
        this.modalImpressum = this.modalImpressum.bind(this)
        this.modalDatenschutz = this.modalDatenschutz.bind(this)
        this.modalLogindialog = this.modalLogindialog.bind(this)
        this.modalError = this.modalError.bind(this)    
        this.anmeldeMenu = this.anmeldeMenu.bind(this)
        this.navBar = this.navBar.bind(this)
        this.getStrings = this.getStrings.bind(this)
        this.getLanguageString = this.getLanguageString.bind(this)
        this.getContent = this.getContent.bind(this)
        this.homeContent = this.homeContent.bind(this)
        this.aboutContent = this.aboutContent.bind(this)
        this.gitarrenschuleContent = this.gitarrenschuleContent.bind(this)
        this.loginContent=this.loginContent.bind(this)
        this.checkLogin = this.checkLogin.bind(this)
        this.eingeloggtContent = this.eingeloggtContent.bind(this)
        this.accountContent = this.accountContent.bind(this)
        this.passwortvergessenContent = this.passwortvergessenContent.bind(this)
        this.sendNewPassword = this.sendNewPassword.bind(this)
        this.neuesPasswortSpeichern = this.neuesPasswortSpeichern.bind(this)
        this.musicContent = this.musicContent.bind(this)
        this.contactContent = this.contactContent.bind(this)
        this.sendeAnfrage = this.sendeAnfrage.bind(this)
        this.neuerSchueler = this.neuerSchueler.bind(this)
        this.getSchueler = this.getSchueler.bind(this)
        this.setSchuelerFilter=this.setSchuelerFilter.bind(this)
        this.changeSchuelerData = this.changeSchuelerData.bind(this)
        this.saveSchueler = this.saveSchueler.bind(this)
        this.changeSchuelerStatus = this.changeSchuelerStatus.bind(this)
        this.deleteSchuelerDialog = this.deleteSchuelerDialog.bind(this)
        this.schuelerLoeschen = this.schuelerLoeschen.bind(this)
        this.setKursthemaid = this.setKursthemaid.bind(this)
        this.setUnterrichtsort = this.setUnterrichtsort.bind(this)
        this.setAltersgruppe = this.setAltersgruppe.bind(this)
        this.setLektionsdauer = this.setLektionsdauer.bind(this)
        this.changeAbo=this.changeAbo.bind(this)
        this.setAboStatus = this.setAboStatus.bind(this)
        this.saveAbo = this.saveAbo.bind(this)
        this.setAbo = this.setAbo.bind(this)
        this.deleteAboDialog = this.deleteAboDialog.bind(this)
        this.aboLoeschen = this.aboLoeschen.bind(this)
        this.neuesAbo = this.neuesAbo.bind(this)
        this.getLektionen = this.getLektionen.bind(this) 
        this.saveLektion=this.saveLektion.bind(this)
        this.editLektion = this.editLektion.bind(this)
        this.modalEditLektion = this.modalEditLektion.bind(this)
        this.lektionSpeichern = this.lektionSpeichern.bind(this)
        this.modalActiveSchuelerAboDialog = this.modalActiveSchuelerAboDialog.bind(this)
        this.unterrichtsMenu = this.unterrichtsMenu.bind(this)
        this.setAnzahlstunden = this.setAnzahlstunden.bind(this)
        this.neueUnterrichtsanfrage = this.neueUnterrichtsanfrage.bind(this)
        this.toggleNav = this.toggleNav.bind(this)
        this.sendeUnterrichtsanfrage = this.sendeUnterrichtsanfrage.bind(this)
        this.modalUnterrichtsAnfrageFertig = this.modalUnterrichtsAnfrageFertig.bind(this)
        this.aboangebotsenden = this.aboangebotsenden.bind(this)
        this.modalMessageBoxDialog = this.modalMessageBoxDialog.bind(this)
        this.modalAngebotSchuelerAboDialog = this.modalAngebotSchuelerAboDialog.bind(this)
        this.checkUnterrichtsAnfrageAnnehmen = this.checkUnterrichtsAnfrageAnnehmen.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
        this.getPdfs = this.getPdfs.bind(this)
    }

    getPdfs(){
        axios.get('/api/pdfs')
        .then(
            result=>{
                if (result.data !==null){
                    this.setState({pdfs: result.data})
                }
            }
        )
    }




    aboangebotsenden(){


        const _this = this

        let activeAbo = this.state.activeAbo
        activeAbo.angebot_versendet_am = new Date()

        function mailSenden(){

            $.ajax(
                {
                type: "POST",
                url: "https://franciscoguitar.de/php/angebotsenden.php",
                data: {'name': _this.state.activeSchueler.name, 'email': _this.state.activeSchueler.email, 'zusammenfassung': _this.state.activeAbo.angebot_html, 'language':_this.state.language, 'hinweis': _this.getLanguageString('$STR_0211'), 'anredezeile': _this.getLanguageString('$STR_0213')},
                
                success: function(html)
                {
                    _this.setState({activeAbo, messageText:html, messageTitle:"Angebot versendet"}, function(){$('#modalMessageBoxDialogButton').click()})
                
                }
                });
        }

        const sql=`UPDATE frc_unterricht_abos SET angebot_versendet_am=now() WHERE id=${this.state.activeAbo.id}`
        const array={sql}
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                mailSenden()
            }
        )




    
    }

    sendeUnterrichtsanfrage(abo, schueler){

        const unterrichtabomitbenutzer = {
            unterrichtAbo:abo,
            benutzer:schueler 
        }        

        const _this = this     

        axios.post('/api/benutzer/unterrichtabomitbenutzer', unterrichtabomitbenutzer)
        .then(
            result=>{
               
            
                    $.ajax(
                        {
                        type: "POST",
                        url: "https://franciscoguitar.de/php/unterrichtsanfrage.php",
                        data: {'name': schueler.name, 'email': schueler.email, 'zusammenfassung': abo.angebot_html, 'language':this.state.language},
                        
                        success: function(html)
                        {
                            _this.setState({activeAbo:abo, activePage:"gitarrenschule"}, function(){$('#modalUnterrichtsAnfrageFertigDialogButton').click()})
                          
                        }
                        });
                
            }
        )

    }

    lektionSpeichern(){
       
        let activeLektion = this.state.activeLektion

        //Zuerst Max pdfid checken
        let sql = 'SELECT MAX(id) FROM frc_pdf'
        axios.post('/api/execcommonquery/integer', {sql})
        .then(
            result=>{
                let maxID = result.data
                if (maxID === null) maxID=0

                 //falls pdfs hochgeladen werden
                if (this.state.file1 !== null){
                    activeLektion.pdf_id = maxID + 1
                }
                if (this.state.file2 !== null){
                    activeLektion.pdf2_id = maxID + 2
                }
                if (this.state.file3 !== null){
                    activeLektion.pdf3_id = maxID + 3
                }
                this.setState({activeLektion})
                axios.post('/api/lektionen', activeLektion)
                .then(
                    result2=>{
                        if (result2.data !==null){
                            this.setState({activeLektion, lektionen:result2.data})
                            this.fileUpload(maxID)
                        }
                    }
                )
            }
        )       

    }

    fileUpload( maxID){             
      
      const url = 'https://franciscoguitar.de/php/upload.php';
       
        let form_data = null
        let pdf = null

        if (this.state.file1 !== null){
            pdf = {
                id:0,
                dateiname:this.state.file1.name
            }            
            axios.post('/api/pdfs',pdf ) 
            form_data = new FormData();
            form_data.append('file', this.state.file1);
            form_data.append('filename', `${maxID+1}.pdf`);
            axios.post(url, form_data)
            .then(
                result=>{
                   // console.log(result.data)
                }
            )
        }
        if (this.state.file2 !== null){
            pdf = {
                id:0,
                dateiname:this.state.file2.name
            }            
            axios.post('/api/pdfs',pdf ) 
            form_data = new FormData();
            form_data.append('file', this.state.file2);
            form_data.append('filename', `${maxID+2}.pdf`);
            axios.post(url, form_data)
            .then(
                result=>{
                   // console.log(result.data)
                }
            )
        }
        if (this.state.file3 !== null){
            pdf = {
                id:0,
                dateiname:this.state.file3.name
            }            
            axios.post('/api/pdfs',pdf ) 
            form_data = new FormData();
            form_data.append('file', this.state.file3);
            form_data.append('filename', `${maxID+3}.pdf`);
            axios.post(url, form_data)
            .then(
                result=>{
                  //  console.log(result.data)
                }
            )
        }
       
        
    }

    modalEditLektion(){

        if(this.state.activeLektion===null) return null

        const lektion = this.state.activeLektion
        const _this = this

        function changePdfValue(value){
            const activeLektion = _this.state.activeLektion
            activeLektion.pdf_id =value
            _this.setState({activeLektion})
        }
        function changePdf2Value(value){
            const activeLektion = _this.state.activeLektion
            activeLektion.pdf2_id =value
            _this.setState({activeLektion})
        }
        function changePdf3Value(value){
            const activeLektion = _this.state.activeLektion
            activeLektion.pdf3_id =value
            _this.setState({activeLektion})
        }

        function pdfRow1(pdf){           
            return(
                <button  onClick={()=>changePdfValue(pdf.id)} className="dropdown-item">{pdf.dateiname}</button>    
            )
        }

        function pdfRow2(pdf){        
            return(
                <button  onClick={()=>changePdf2Value(pdf.id)} className="dropdown-item">{pdf.dateiname}</button>    
            )
        }

        function pdfRow3(pdf){        
            return(
                <button  onClick={()=>changePdf3Value(pdf.id)} className="dropdown-item">{pdf.dateiname}</button>    
            )
        }


        const dropDownPdfs1 =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Pdf wählen
                </button>
                <ul className="dropdown-menu">
                  {this.state.pdfs.map(pdfRow1)}                
                </ul>
            </div>
        )

        const dropDownPdfs2 =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Pdf wählen
                </button>
                <ul className="dropdown-menu">
                {this.state.pdfs.map(pdfRow2)}                
                </ul>
            </div>
        )
        const dropDownPdfs3 =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Pdf wählen
                </button>
                <ul className="dropdown-menu">
                {this.state.pdfs.map(pdfRow3)}                
                </ul>
            </div>
        )

        const body=(
            <div>
                <h4>Lektion {lektion.lfdnr}</h4>
                
                <div className="form-group" style={{width:'200px'}}>
                    <label htmlFor="untdatum">Datum</label>         
                    <input id="untdatum" className="form-control" type="datetime-local" name="unterrichtszeit"  onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.unterrichtszeit}  />
                </div>

                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="form-group">
                                <label>Thema 1</label>
                                    <input className="form-control" type="text"  name="thema" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.thema} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>PDF 1 (id)</label>
                                    <input className="form-control" type="number"  style={{width:'100px'}}  name="pdf_id" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.pdf_id} />
                                    <input type="file" accept="application/pdf" name="file1" id="selectFileButton"  onChange={(e)=>this.setState({file1:e.target.files[0]}) }/>
                                    {dropDownPdfs1}
                                    </div>
                                   
                            </td>
                            <td>
                                <div className="form-group">
                                <label>Video-ID 1</label>
                                    <input className="form-control" type="text"  name="videourl" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.videourl} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>Bemerkungen 1</label>
                                    <textarea className="form-control" type="text" rows={3} name="bemerkungen" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.bemerkungen} />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className="form-group">
                                <label>Thema 2</label>
                                    <input className="form-control" type="text"  name="thema2" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.thema2} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>PDF 2 (id)</label>
                                    <input className="form-control" type="number"  style={{width:'100px'}}  name="pdf2_id" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.pdf2_id} />
                                    <input type="file" accept="application/pdf" name="file2" id="selectFileButton2"  onChange={(e)=>this.setState({file2:e.target.files[0]}) }/>
                                    {dropDownPdfs2}
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>Video-ID 2</label>
                                    <input className="form-control" type="text"  name="videourl2" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.videourl2} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>Bemerkungen 2</label>
                                    <textarea className="form-control" type="text" rows={3} name="bemerkungen" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.bemerkungen2} />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className="form-group">
                                <label>Thema 3</label>
                                    <input className="form-control" type="text"  name="thema3" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.thema3} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>PDF 3 (id)</label>
                                    <input className="form-control" type="number" style={{width:'100px'}} name="pdf3_id" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.pdf3_id} />
                                    <input type="file" accept="application/pdf" name="file3" id="selectFileButton3"  onChange={(e)=>this.setState({file3:e.target.files[0]}) }/>
                                    {dropDownPdfs3}
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>Video-ID 3</label>
                                    <input className="form-control" type="text"  name="videourl3" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.videourl3} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                <label>Bemerkungen 3</label>
                                    <textarea className="form-control" type="text" rows={3} name="bemerkungen3" onChange={(e)=>this.saveLektion(e,lektion.id)}  value={lektion.bemerkungen3} />
                                </div>
                            </td>
                        </tr>
                       
                    </tbody>
                </table>
             
            </div>
        )
    
        return(
    
            <Modal
                modalid="modalEditLektionDialog"
                buttonid="modalEditLektionDialogButton"   
                title="Lektion bearbeiten" 
                body={body} 
                okButtonTitle="Lektion speichern"
                okButtonClick={this.lektionSpeichern}
                closeButtonTitle="Abbrechen"
                modalExtraLarge={true}
            />
        )
    }

    modalActiveSchuelerAboDialog(){    
        if (this.state.activeSchuelerAbo === null || this.state.activeSchuelerAbo===undefined) return null       

        const body=(
            <div>
                <center>
                 {stringToHML(this.state.activeSchuelerAbo.angebot_html)}
                </center>
            </div>
        )

        return(
    
            <Modal
                modalid="modalActiveSchuelerAboDialog"
                buttonid="modalActiveSchuelerAboDialogButton"   
                title={this.getLanguageString('$STR_0220')} 
                body={body} 
                messagebox={true}
                closeButtonTitle="OK"
                modalLarge={true}
            />
        )
    }

    checkUnterrichtsAnfrageAnnehmen(){

        const _this = this
        const sql = `UPDATE frc_unterricht_abos SET angenommen_am=now() WHERE id=${this.state.angebotSchuelerAbo.id}`
        const array={sql}
        axios.post('/api/execcommonquery', array);

        const angebotSchuelerAbo = this.state.angebotSchuelerAbo
        angebotSchuelerAbo.angenommen_am= new Date()


        $.ajax(
            {
            type: "POST",
            url: "https://franciscoguitar.de/php/angebotannehmen.php",
            data: {'name': _this.state.activeUser.name, 'email': _this.state.activeUser.email, 'zusammenfassung': angebotSchuelerAbo.angebot_html, 'language':_this.state.language},
            
            success: function(html)
            {
                 const messageText=<div><big>{_this.getLanguageString('$STR_0219')}</big><p>{_this.getLanguageString('$STR_0143')}</p><p>Francisco</p> </div>
       
                _this.setState({angebotSchuelerAbo, zahlungsBedingungenGelesen:false, messageTitle:'', messageText}, function(){$('#modalMessageBoxDialogButton').click()})
            
            }
            });
       
       

    }


    modalAngebotSchuelerAboDialog(){    
        if (this.state.angebotSchuelerAbo === null || this.state.angebotSchuelerAbo===undefined) return null      
        
        let  bestellFehlerText = null
        if (this.state.zahlungsBedingungenGelesen===false){
            bestellFehlerText = this.getLanguageString('$STR_0218')
        }

        const body=(
            <div>
                {stringToHML(this.state.angebotSchuelerAbo.angebot_html)}
                <font color="red"><b>{bestellFehlerText}</b></font>
                <div className="form-check form-switch">
                    <input className="form-check-input"  type="checkbox" onChange={()=>this.setState({zahlungsBedingungenGelesen : !this.state.zahlungsBedingungenGelesen})} role="switch" id="flexSwitchCheckDefault" checked={this.state.zahlungsBedingungenGelesen===true}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{this.getLanguageString('$STR_0217')}</label>
                </div>
                            
            </div>
        )

        let okButton = <button onClick={this.checkUnterrichtsAnfrageAnnehmen} data-bs-dismiss="modal" className="btn btn-primary">{this.getLanguageString('$STR_0216')}</button>
        if (this.state.zahlungsBedingungenGelesen===false){
            okButton = <button disabled className=" btn btn-primary">{this.getLanguageString('$STR_0216')}</button>
        }

        return(
    
            <Modal
                modalid="modalAngebotSchuelerAboDialog"
                buttonid="modalAngebotSchuelerAboDialogButton"   
                title={this.getLanguageString('$STR_0210')} 
                body={body} 
                modalLarge={true}
                closeButtonTitle={this.getLanguageString('$STR_0184')}
                okButton={okButton}
            />
        )
    }

    editLektion(id){
        this.getPdfs()
        this.state.lektionen.forEach(
            lektion=>{
                if (lektion.id===id){
                   this.setState({activeLektion:lektion}, function(){$('#modalEditLektionDialogButton').click()})
                }
            }
        )
    }

    saveLektion(e){
      
        let activeLektion= this.state.activeLektion       
        if (e.target.name==="thema") activeLektion.thema = e.target.value;
        if (e.target.name==="thema2") activeLektion.thema2 = e.target.value;
        if (e.target.name==="thema3") activeLektion.thema3 = e.target.value;
        if (e.target.name==="pdf_id") activeLektion.pdf_id = Number(e.target.value);
        if (e.target.name==="pdf2_id") activeLektion.pdf2_id = Number(e.target.value);
        if (e.target.name==="pdf3_id") activeLektion.pdf3_id = Number(e.target.value);
        if (e.target.name==="bemerkungen") activeLektion.bemerkungen = e.target.value;
        if (e.target.name==="bemerkungen2") activeLektion.bemerkungen2 = e.target.value;
        if (e.target.name==="bemerkungen3") activeLektion.bemerkungen3 = e.target.value;
        if (e.target.name==="videourl") activeLektion.videourl = e.target.value;
        if (e.target.name==="videourl2") activeLektion.videourl2 = e.target.value;
        if (e.target.name==="videourl3") activeLektion.videourl3 = e.target.value;
        if (e.target.name==="unterrichtszeit") activeLektion.unterrichtszeit = e.target.value;
        this.setState({activeLektion})       
    }

    getLektionen(aboid){

        const _this = this

        this.setState({lektionen:[]}, function(){

            axios.get('/api/lektionen/' + aboid)
                    .then(
                        result=>{
                            if (result.data !==null){
                                _this.setState({lektionen: result.data})
                            }
                        }
                    )


        })

        

    }

    neuesAbo(){

        const neuesAbo={
            id:0,
            benutzer_id:this.state.activeSchueler.id,
            benutzer2_id:0,
            kursthema_id:0,
            unterrichtsort_id:2,
            altersgruppe:'erwachsene',
            lektionsdauer:45,
            gruppenunterricht:0,
            anzahl_lektionen:5,
            rabatt:0,
            unterrichtszeit:'',
            preis_pro_lektion:62,
            datum_beginn_abo:null,
            datum_ende_abo:null,
            bemerkungen:'',
            gesamtpreis:0,
            wegpauschale:0,
            andererschueler:'',
            andererschueler_email:'',
            status:'angebot',
            angebot_html:'',
            angenommen_am:null,
            zahlungsbedingungen:''

        }
        axios.post('/api/unterrichtabos', neuesAbo)
        .then(
            result=>{
                let activeSchueler = this.state.activeSchueler
                activeSchueler.unterrichtabos = result.data
                result.data.forEach(
                    abo=>{
                        if (abo.kursthema_id===0){
                            this.setState({activeSchueler, activeAbo:abo, activeLektion:null, lektionen:[]})
                        }
                    }
                )
                
            }
        )
    }

    saveAbo(abo){

        

        axios.post('/api/unterrichtabos', abo)
        .then(
            result=>{
                if (result.data !== null){
                    let activeSchueler = this.state.activeSchueler
                    activeSchueler.unterrichtabos = result.data
                    this.setState({activeSchueler, activeAbo: abo})
                }               
            }
        )
    }

    changeAbo(e){
        
        let activeAbo = this.state.activeAbo
        if (e.target.name==="gruppenunterricht"){
            activeAbo.gruppenunterricht=0
            if (e.target.checked)activeAbo.gruppenunterricht=1
        }
        
        if (e.target.name==="andererschueler")activeAbo.andererschueler=e.target.value
        if (e.target.name==="andererschueler_email")activeAbo.andererschueler_email=e.target.value
        if (e.target.name==="anzahl_lektionen")activeAbo.anzahl_lektionen=e.target.value
        if (e.target.name==="datum_beginn_abo")activeAbo.datum_beginn_abo=e.target.value
        if (e.target.name==="datum_ende_abo")activeAbo.datum_ende_abo=e.target.value
        if (e.target.name==="unterrichtszeit")activeAbo.unterrichtszeit=e.target.value
        if (e.target.name==="bemerkungen")activeAbo.bemerkungen=e.target.value
        if (e.target.name==="zahlungsbedingungen")activeAbo.zahlungsbedingungen=e.target.value
        if (e.target.name==="gesamtpreis")activeAbo.gesamtpreis=Number(e.target.value)
        if (e.target.name==="wegpauschale")activeAbo.wegpauschale=Number(e.target.value)
        

        this.setState({activeAbo}) 

        if (e.target.name==="anzahl_lektionen"){
            this.getLektionen(activeAbo.id)
        }

    }

    setAboStatus(value){
        let activeAbo = this.state.activeAbo
        activeAbo.status = value 
        this.setState({activeAbo}) 
    }

    setLektionsdauer(value){
        let activeAbo = this.state.activeAbo
        activeAbo.lektionsdauer = value 
        this.setState({activeAbo}) 
    }

    setAltersgruppe(value){
        let activeAbo = this.state.activeAbo
        activeAbo.altersgruppe = value 
        this.setState({activeAbo}) 
    }

    setAnzahlstunden(value){
        let activeAbo = this.state.activeAbo
        activeAbo.anzahl_lektionen = value 
        this.setState({activeAbo}) 
    }

    setUnterrichtsort(value){
        let activeAbo = this.state.activeAbo
        activeAbo.unterrichtsort_id = value 
        this.setState({activeAbo}) 
    }

    setKursthemaid(value){
        let activeAbo = this.state.activeAbo
        activeAbo.kursthema_id = value 
        this.setState({activeAbo}) 
    }

    schuelerLoeschen(){
        
        const array={
            sql:`DELETE FROM hd_benutzer WHERE id=${this.state.activeSchueler.id}; DELETE FROM frc_unterricht_abos WHERE benutzer_id=${this.state.activeSchueler.id}`
        }


        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                
                   this.getSchueler()
                   $('.modal').remove();
                    $('.modal-backdrop').remove();
                    $('body').removeClass( "modal-open" );      

               
            }
        )
    }

    neuerSchueler(){
        const neuerSchueler={
            id:0,
            email:'',
            name:'Neuer Schüler',
            anmelder_name:'',
            ist_admin:0,
            status:'angemeldet',
            strassenr:'',
            plzort:'',
            telefon:'',
            bemerkungen:'',
            geburtsjahr:0
        }
        axios.post('/api/benutzer',neuerSchueler )
        .then(
            result=>{
                if (result.data !==null){
                    result.data.forEach(
                        sch=>{
                            if (sch.name==="Neuer Schüler"){
                                this.setState({schueler: result.data, activeSchueler:sch})

                            }
                        }
                    )
                }
            }
        )

    }

    saveSchueler(){
        const schueler={
            id:this.state.activeSchueler.id,
            email:this.state.activeSchueler.email,
            name:this.state.activeSchueler.name,
            ist_admin:this.state.activeSchueler.ist_admin,
            status:this.state.activeSchueler.status,
            strassenr:this.state.activeSchueler.strassenr,
            plzort:this.state.activeSchueler.plzort,
            telefon:this.state.activeSchueler.telefon,
            bemerkungen:this.state.activeSchueler.bemerkungen,
            geburtsjahr:this.state.activeSchueler.geburtsjahr
        }
        axios.post('/api/benutzer', schueler)
        .then(
            result=>{
                if (result.data !== null){
                    result.data.forEach(
                        (sch)=> {
                            if (sch.email===this.state.activeSchueler.email){
                                this.setState({schueler: result.data, activeSchueler:sch})
                            }
                        }
                    )                    
                }
            }
        )
    }

    changeSchuelerStatus(status){
        let activeSchueler = this.state.activeSchueler
        activeSchueler.status = status
        this.setState({activeSchueler})

    }

    changeSchuelerData(e){

        let activeSchueler = this.state.activeSchueler
        if (e.target.name==="name" )activeSchueler.name=e.target.value
        if (e.target.name==="anmelder_name" )activeSchueler.anmelder_name=e.target.value
        if (e.target.name==="email" )activeSchueler.email=e.target.value
        if (e.target.name==="telefon" )activeSchueler.telefon=e.target.value
        if (e.target.name==="strassenr" )activeSchueler.strassenr=e.target.value
        if (e.target.name==="plzort" )activeSchueler.plzort=e.target.value
        if (e.target.name==="geburtsjahr" )activeSchueler.geburtsjahr=e.target.value
        if (e.target.name==="bemerkungen" )activeSchueler.bemerkungen=e.target.value
        if (e.target.name==="andererschueler" )activeSchueler.andererschueler=e.target.value
        this.setState({activeSchueler})
    }

    getSchueler(){
        const _this = this
        axios.get('/api/benutzer')
        .then(
            result=>{
                if (result.data !== null){   
                    result.data.forEach(
                        (sch)=> {
                            if (this.state.activeSchueler!==null && sch.email===this.state.activeSchueler.email){
                                this.setState({activeSchueler:sch})                                
                            }                           
                        }                        
                    )                       
                    
                    this.setState({schueler: result.data}, function(){_this.setSchuelerFilter(_this.state.schuelerFilter)})
                }
            }
        )
    }

    setSchuelerFilter(filter){
        let filteredSchueler = []
        
        this.state.schueler.forEach(
            (schueler)=>{
                if (filter=='alle') filteredSchueler.push(schueler)
                if (filter=='aktiv' && schueler.status==='aktiv') filteredSchueler.push(schueler)                
                if (filter=='inaktiv' && schueler.status==='inaktiv')filteredSchueler.push(schueler)
                if (filter=='angemeldet' && schueler.status==='angemeldet')filteredSchueler.push(schueler)  
            }
        )
        this.setState({filteredSchueler, schuelerFilter: filter, activeSchueler:null})

    }

    sendeAnfrage(){

        const _this = this
        $.ajax(
            {
            type: "POST",
            url: "https://franciscoguitar.de/php/kontakt.php",
            data: {'anfrage_name': this.state.anfrage_name, 'anfrage_email': this.state.anfrage_email, 'anfrage_text': this.state.anfrage_text},
            
            success: function(html)
            {

              _this.setState({sentEmail:true,anfrage_name:'',anfrage_email:'', anfrage_text:''})
            }
            });
    }

    contactContent(){

        let sendButton =  <button  onClick={this.sendeAnfrage} className="btn btn-form btn-primary display-4">{this.getLanguageString('$STR_0031')}</button>
        if (this.state.anfrage_email === "" && this.state.anfrage_name==="" && this.state.anfrage_text===""){
            sendButton =  <button className="btn btn-form btn-primary display-4" disabled >{this.getLanguageString('$STR_0031')}</button>
        }

        let form = (
            <>
                <h2 className="mbr-fonts-style mbr-section-heading display-2">{this.getLanguageString('$STR_0098')}</h2>
                <br/>
                {this.getLanguageString('$STR_0199')}<br/>
    
                <h4>Tel. CH +41.77.4792490<br/></h4><br />
                
                <h2 className="mbr-fonts-style mbr-section-heading display-2">{this.getLanguageString('$STR_0026')}</h2>            


                <input type="hidden" name="kontakt_anfrage" value="1" />
        
                <div className="row input-main">
                    <div className="col-md-6 input-wrap" data-for="$vorname_name">
                        <input type="text" className="field display-7 form-control" onChange={(e)=>this.setState({anfrage_name:e.target.value})} data-form-field="Name" placeholder={this.getLanguageString('$STR_0027')} required="" id="name-form2-u" value={this.state.anfrage_name} />
                    </div>
                    <div className="col-md-6 input-wrap" data-for="email">
                        <input type="email" className="field display-7 form-control" onChange={(e)=>this.setState({anfrage_email:e.target.value})}  data-form-field="Email" placeholder={this.getLanguageString('$STR_0028')} required="" id="email-form2-u" value={this.state.anfrage_email}/>
                    </div>
                </div>

                <div className="form-group" data-for="message">
                    <textarea type="text" className="form-control display-7 form-control" onChange={(e)=>this.setState({anfrage_text:e.target.value})}  rows="7" placeholder={this.getLanguageString('$STR_0030')} required="" data-form-field="Message" id="message-form2-u">{this.state.anfrage_text}</textarea>
                </div>
                <span className="input-group-btn">        
                   {sendButton}
                </span>
            </>

        )

        if (this.state.sentEmail){
            form = (
                <h4>{this.getLanguageString('$STR_0038')}</h4>
            )
        }

        let content = (
            <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12" >
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5">                                    
                              {form}
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )



        return(
            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about4.png)'}}>    
                {content}
            </section>
        )
    }



    musicContent(){

        function stueckerow(stueck){

            return(
                <tr><td>{stueck.titel}</td><td>{stueck.komponist}</td></tr>
            )

        }

        const content1=(
            <>
            <img src="/assets/images/concert.png" width={400} height={200}/>    
            <h3 className="styled">{this.getLanguageString('$STR_0047')} <em>{this.getLanguageString('$STR_0048')}</em></h3> {this.getLanguageString('$STR_0049')}<br /><br />
            
            <table width={400}>
                     <tbody>
                        {this.state.repertoire.map(stueckerow)}
                    </tbody>
                </table>

                </>

        )

        const content2=(
                <div  className="html5gallery" data-skin="light" data-width="700" data-height="430">
                    <a href="/assets/images/cd3_.jpg"><img src="/assets/images/cd3.jpg" alt={this.getLanguageString('$STR_0054')} /></a>
                    <a href="/assets/images/cd8_.jpg"><img src="/assets/images/cd8.jpg" alt={this.getLanguageString('$STR_0055')}/></a>
                    <a href="/assets/images/cd1_.jpg"><img src="/assets/images/cd1.jpg" alt={this.getLanguageString('$STR_0056')}/></a>
                    <a href="/assets/images/cd2_.jpg"><img src="/assets/images/cd2.jpg" alt={this.getLanguageString('$STR_0057')}/></a>
                    <a href="/assets/images/cd5_.jpg"><img src="/assets/images/cd5.jpg" alt={this.getLanguageString('$STR_0058')}/></a>
                    <a href="/assets/images/cd6_.jpg"><img src="/assets/images/cd6.jpg" alt={this.getLanguageString('$STR_0059')}/></a>
                    <a href="/assets/images/cd9_.jpg"><img src="/assets/images/cd9.jpg" alt=""/></a>
                    <a href="/assets/images/cd10_.jpg"><img src="/assets/images/cd10.jpg" alt=""/></a>
                </div>
        )


        return(
            <>
            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about.png)'}}>     
                <div className="container text-center" data-stellar-background-ratio=".5" >
                    <div className="row slider-wrapper">
                        <div className="col-md-12">
                            <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                                            
                                 {content1}
                            </div>
                        </div>
                    </div>
                </div>                 
             
            </section>
            
            </>
        )
    }

    neuesPasswortSpeichern(){

        if (this.state.password1!==this.state.password2){
            this.setState({errortext:this.getLanguageString('$STR_0094')})
            return
        }

        const array={
            sql:`UPDATE hd_benutzer SET passwort=password('${this.state.password1}') WHERE id=${this.state.activeUser.id}`
        }
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                this.setState({errortext:this.getLanguageString('$STR_0095')})
            }
        )      

    }

    sendNewPassword(){

        const array={email: this.state.forgotemail}
        axios.post('/api/benutzer/passwortvergessen', array)
        .then(
            result=>{
                if (result.data.id===0){
                    this.setState({errortext:this.getLanguageString('$STR_0081')})

                }
                else{
                    
                    const url =  `https://franciscoguitar.de/php/passwortvergessen.php?passwort=${result.data.passwort}&email=${this.state.forgotemail}&language=${this.state.language}`
                    const _this = this
                    $.ajax({

                        url ,
                        type : 'GET',
                        success : function (result) {
                           _this.setState({sentEmail: true})
                        },
                        error : function () {
                           console.log ('error');
                        }
                   
                      });

                }
            }
        )
    }

    passwortvergessenContent(){

   
        let sendButton =  <button className="btn btn-form btn-primary display-4" onClick={this.sendNewPassword}  >{this.getLanguageString('$STR_0080')} </button>
        if (this.state.forgotemail===""){
            sendButton = <button className="btn btn-form btn-primary display-4" disabled>{this.getLanguageString('$STR_0080')}</button>
        }       



        let form = (
            <div className="form-1 col-md-12 col-sm-12" >
                <h2 className="mbr-fonts-style mbr-section-heading display-2">{this.getLanguageString('$STR_0078')}</h2>
                {this.state.errortext}
                <p>{this.getLanguageString('$STR_0079')}</p>
                
                <div className="row row-sm-offset">

                    <div className="col-md-12 multi-horizontal" data-for="email">
                        <div className="form-group">
                            <input type="email" className="form-control" onChange={(e)=>this.setState({forgotemail:e.target.value})} placeholder={this.getLanguageString('$STR_0028')} data-form-field="Email" required="" id="email-form1-2" value={this.state.forgotemail} />
                        </div>
                    </div>                         
                </div>

                <span className="input-group-btn">
                   {sendButton}
                </span>            
            </div>

        )

        let content = form

        if (this.state.sentEmail){
            content = (
                <div className="form-1 col-md-12 col-sm-12" >
                    <h2 className="mbr-fonts-style mbr-section-heading display-2">{this.getLanguageString('$STR_0088')}</h2>                   
                </div>               
            )
        }

      
        return content;
       

        return (
            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about5.png)'}}>    
                <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                <div className="col-md-12" >
                    <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                        <div className="row justify-content-center pt-5">  
                                {content}            
                        </div>
                    </div>
                </div>
                </div> 
                </div>      
            </section>
        )

    }

    accountContent(){
        let saveButton =  <button onClick={this.neuesPasswortSpeichern} className="btn btn-form btn-primary display-4">{this.getLanguageString('$STR_0093')}</button>
        if (this.state.password1==="" && this.state.password2==""){
            saveButton =  <button disabled className="btn btn-form btn-primary display-4">{this.getLanguageString('$STR_0093')}</button>
        }
        


        return (
            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about5.png)'}}>
            <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12" >
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5">
                                <h2 className="mbr-fonts-style mbr-section-heading display-2">{this.getLanguageString('$STR_0089')}</h2>
                                <p>{this.getLanguageString('$STR_0096')} {this.state.activeUser.name}</p><br/>                                
                                <div className="row row-sm-offset">
                                    {this.state.errortext}
                                    <div className="col-md-12 multi-horizontal" data-for="password1">
                                        <div className="form-group">
                                            <input type="password" className="form-control" onChange={(e)=>this.setState({password1:e.target.value})} placeholder={this.getLanguageString('$STR_0091')} data-form-field="password1" required="" id="password-form1-2" value={this.state.password1} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 multi-horizontal" data-for="password2">
                                        <div className="form-group">
                                            <input type="password" className="form-control" onChange={(e)=>this.setState({password2:e.target.value})} placeholder={this.getLanguageString('$STR_0092')} required="" data-form-field="password2" id="password1-form1-2"  value={this.state.password2} />
                                        </div>
                                    </div>
                                    </div>

                                    <span className="input-group-btn">
                                     {saveButton}
                                    </span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        )
    }

    eingeloggtContent(){

        return(
            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about5.png)'}}>
            <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12" >
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5">
                                {this.getLanguageString('$STR_0075')}
                                
                                <div>
                                <a href="#" onClick={()=>this.setState({activePage:'lessons'})} className="btn btn-primary">OK</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        )

    }


    checkLogin(){

        const benutzer={
            email:this.state.email,
            passwort: this.state.passwort
        }

        axios.post('/api/benutzer/check', benutzer)
       .then(
            result=>{
                if (result.data.id===0){
                    this.setState({errortext:this.getLanguageString('$STR_0074'), isLoggedIn:false})                   
                }
                else{                
                    this.setState({activeUser:result.data})  

                    axios.get('/api/lektionen/benutzer/' + result.data.id)
                    .then(
                        result2=>{
                            if (result2.data !== null){                         
                                this.setState({schueler_lektionen: result2.data})                       
                            }
                        }
                    )
                    
                    axios.get('/api/unterrichtabos/'+ result.data.id)
                    .then(
                        result3=>{
                            if (result3.data !== null) {
                                let activeSchuelerAbo = null
                                let angebotSchuelerAbo = null
                                result3.data.forEach(
                                    abo=>{
                                        if (abo.status==='aktiv'){
                                            activeSchuelerAbo = abo
                                        }
                                        if (abo.status==='angebot'){
                                            angebotSchuelerAbo = abo
                                        }
                                    }
                                )
                                this.setState({schuelerAbos: result3.data, activeSchuelerAbo,angebotSchuelerAbo})   
                            }                 
                                                         
                        }
                    )

                    let activePage="meineAbos"
                    if (result.data.ist_admin===1)activePage="admin"
                   
                    this.setState({ errortext:'', isLoggedIn:true, activePage}, function(){$('#modalLogindialogButton').click()})
                }
                
            }
       )

    }

    loginContent(){

        let okButton = <button  className="btn btn-form btn-primary display-4" onClick={this.checkLogin}   >{this.getLanguageString('$STR_0031')}</button>
        if (this.state.email === "" || this.state.passwort===""){
            okButton = <button  className="btn btn-form btn-primary display-4" disabled   >{this.getLanguageString('$STR_0031')}</button>
        }
        

        let form=(
            <div className="form-1 col-md-12 col-sm-12" >
                <h2 className="mbr-fonts-style mbr-section-heading display-2">{this.getLanguageString('$STR_0066')}</h2>
                {this.state.errortext}
                <div className="row row-sm-offset">
                    <div className="col-md-12 multi-horizontal" data-for="email">
                        <div className="form-group">
                            <input type="email" className="form-control" onChange={(e)=>this.setState({email:e.target.value})} placeholder="Email" data-form-field="Email" required="" id="email-form1-2" value={this.state.email} />
                        </div>
                    </div>
                    <div className="col-md-12 multi-horizontal" data-for="password">
                        <div className="form-group">
                            <input type="password" className="form-control" onChange={(e)=>this.setState({passwort:e.target.value})}  placeholder={this.getLanguageString('$STR_0073')} required="" data-form-field="password" id="password-form1-2"  value={this.state.passwort} />
                        </div>
                    </div>
                </div>
                <span className="input-group-btn">
                    {okButton}
                </span>   
                <div style={{marginTop:'20px'}}>
                <a className="btn btn-primary" onClick={()=>this.setState({passwortvergessen:true})} href="#">{this.getLanguageString('$STR_0078')}</a>
                </div>    
            </div>
        )

        let content2 =(            
            <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12" >
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5">
                                    {form}
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )

        return content2

       
    }

    toggleNav(){
        if (this.navBarHeight() > 50){
                    $('#toggler').click()
        }  
    }

    neueUnterrichtsanfrage(){    

        this.setState({activeAbo:null})
        this.toggleNav()
        this.setState({
            activeAbo:{
                id:0,
                benutzer_id:0,
                benutzer2_id:0,
                kursthema_id:1,
                unterrichtsort_id:2,
                altersgruppe:'erwachsene',
                lektionsdauer:45,
                gruppenunterricht:0,
                anzahl_lektionen:5,
                rabatt:0,
                unterrichtszeit:'',
                preis_pro_lektion:62,
                datum_beginn_abo:null,
                datum_ende_abo:null,
                bemerkungen:'',
                gesamtpreis:0,
                wegpauschale:0,
                andererschueler:'',
                andererschueler_email:'',
                status:'anfrage',
                angebot_html:'',
                angenommen_am:null,
                angebot_versendet_am:null,
                zahlungsbedingungen:''
            },
            activePage:'unterrichtsanfrage',
            activeSchueler:{
                id:0,
                email:'',
                name:'',
                passwort:'',
                anmelder_name:'',
                ist_admin:0,
                status:'angemeldet',
                strassenr:'',
                plzort:'',
                telefon:'',
                bemerkungen:'',
                geburtsjahr:0
            }
        })

    }

    unterrichtsMenu(){


        const _this = this
                
       

        return(

            <li className="nav-item dropdown">
                <a className="nav-link link text-warning dropdown-toggle display-4" href="#" id="navbarDropdown"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.getLanguageString('$STR_0004').toUpperCase()}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a className="text-warning dropdown-item display-4" href="#"   onClick={this.neueUnterrichtsanfrage}><font color="b0b0b0">{this.getLanguageString('$STR_0122').toUpperCase()}</font></a>
                    
                    <div className="dropdown-divider"></div>
                    <a className="text-warning dropdown-item display-4" href="#" onClick={()=>this.setState({activePage:'lernvideos'}, function(){_this.toggleNav()})}><font color="b0b0b0">{this.getLanguageString('$STR_0128').toUpperCase()}</font></a>
       
                </div>
            </li>          

        )
    }

    navBarHeight(){
        let navbarSupportedContent = document.getElementById("navbarSupportedContent")
        let navHeight = 30;
        if (navbarSupportedContent !== null){           
           // console.log("navbar", navbarSupportedContent.offsetHeight)
            navHeight = navbarSupportedContent.offsetHeight
        }
        return navHeight


    }

    anmeldeMenu(){
       
        let toggleNav = ()=>function(){}
        if (this.navBarHeight() > 50){
            toggleNav = ()=>function(){$('#toggler').click()}
        }

        var adminlink = null;
        if (this.state.activeUser!==undefined && this.state.activeUser !== null && this.state.activeUser.ist_admin===1){
            adminlink =             
            <>
                <a className="text-warning dropdown-item display-4" onClick={()=>this.setState({activePage:'admin'}, toggleNav())} href="#"><font color="b0b0b0">ADMIN</font></a>
            <div className="dropdown-divider"></div>
            
            </>
        }

        const _this = this

        var anmeldeLink = 
            <li className="nav-item">
                <a className="nav-link link text-warning display-4" href="#" onClick={()=>this.setState({errortext:false, passwortvergessen:false, sentEmail:false}, function(){_this.showModalLoginDoalog()})}><font color="b0b0b0">{this.getLanguageString('$STR_0066').toUpperCase()}</font></a>
            </li>
        
        var meineAbosLink=(
            <a className="text-warning dropdown-item display-4" href="#" onClick={()=>this.setState({activePage:'meineAbos'}, toggleNav())}><font color="b0b0b0">{this.getLanguageString('$STR_0215').toUpperCase()}</font></a>
        )
        if (this.state.activeUser !== undefined &&  this.state.activeUser !== null  && this.state.activeUser.ist_admin===1)meineAbosLink=null

        if (this.state.isLoggedIn){
        
           anmeldeLink = 
            <li className="nav-item dropdown">
                <a className="nav-link link text-warning dropdown-toggle display-4" href="#" id="navbarDropdown"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.getLanguageString('$STR_0089').toUpperCase()}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="text-warning dropdown-item display-4" href="#" onClick={()=>this.setState({activePage:'account'}, toggleNav())}><font color="b0b0b0">{this.getLanguageString('$STR_0090').toUpperCase()}</font></a>
                {meineAbosLink}
                <div className="dropdown-divider"></div>
                <a className="text-warning dropdown-item display-4" href="#" onClick={()=>this.setState({activePage:'home', isLoggedIn:false, activeUser:null}, toggleNav())}><font color="b0b0b0">{this.getLanguageString('$STR_0076').toUpperCase()}</font></a>
                <div className="dropdown-divider"></div>
                {adminlink}
                <a className="text-warning dropdown-item display-4" ><font color="b0b0b0">{this.state.activeUser.name}</font></a>
                </div>
            </li>          

        }


        // nur fürs testen
   //     anmeldeLink = <li className="nav-item"><a className="text-warning dropdown-item display-4" onClick={()=>this.setState({activePage:'admin'})} href="#">Admin</a></li>

        return anmeldeLink;   
    }

   

    getStrings(){      
        
        axios.get('/api/languagestrings')
        .then(
            result =>{
                if (result.data !== null){
                    this.setState({strings: result.data})
                   
                }
            }
        )
       
       
    }

    getLanguageString(value){
       
        var result = ''       
        this.state.strings.forEach(
            array =>{                          
                if (array.number.trim()=== value) {
                    
                    if (this.state.language==='de') result = array.lang_de
                    if (this.state.language==='en') result = array.lang_en
                    if (this.state.language==='es') result = array.lang_es
                    if (this.state.language==='fr') result = array.lang_fr
                    if (this.state.language==='it') result = array.lang_it
                    if (this.state.language==='pt') result = array.lang_pt
                }              
            }           
        )
       
        return result.replaceAll("\\", "");
    }


    componentWillMount(){

       
   
        this.getStrings()
    
        axios.get('/api/hello/server')
        .then(
            result=>{          
                this.setState({server:result.data,})
            }
        )
        

        axios.get('/api/repertoire')
        .then(
            result=>{
                if (result.data !== null){
                    this.setState({repertoire: result.data})
                }
            }
        )

        this.setSchuelerFilter('aktiv')

        var language = 'en'

        const locale = new Intl.Locale(navigator.language);

        switch (locale.language) {
            case 'de':
                language = 'de'
                break;
            case 'es':
                language = 'es'
                break;
            case 'fr':
                language = 'fr'
                break;
            case 'it':
                language = 'it'
                break;
            case 'pt':
                language = 'pt'
                break;
        
        }
        this.setState({language})

        axios.get('/api/kursthemen')
        .then(
            result=>{
                if (result.data !== null){
                    this.setState({kursthemen:result.data})
                }
            }
        )
        axios.get('/api/unterrichtsorte')
        .then(
            result=>{
                if (result.data !== null){
                    this.setState({unterrichtsorte:result.data})
                }
            }
        )
        axios.get('/api/lektionenpreise')
        .then(
            result=>{
                if (result.data !== null)
                    this.setState({lektionenpreise: result.data})
            }
        )

        
        


        this.getSchueler()

    }

    modalUnterrichtsAnfrageFertig(){

        if (this.state.activeAbo === null) return null

        const body=(
            <>
            <h4>{this.getLanguageString('$STR_0148')}</h4>
            <p>{this.getLanguageString('$STR_0145')} {this.getLanguageString('$STR_0143')}</p>
            {stringToHML(this.state.activeAbo.angebot_html)}
            </>
        )

        return(
          <Modal 
            modalid="modalUnterrichtsAnfrageFertigDialog" 
            buttonid="modalUnterrichtsAnfrageFertigDialogButton" 
            body={body}
            title={this.getLanguageString('$STR_0109')}
            messagebox={true}
            okButtonenabled={true}  

         /> 
        )
    }

    modalMessageBoxDialog(){

        return(

            <Modal 
              modalid="modalMessageBoxDialog" 
              buttonid="modalMessageBoxDialogButton" 
              body={this.state.messageText}
              title={this.state.messageTitle}
              messagebox={true}
              okButtonenabled={true} 
               
           /> 
          )
    }



    

    modalError(){
        return(
          <Modal 
            modalid="error" 
            buttonid="errorButton" 
            body={this.state.errortext}
            title="Fehler bei der Eingabe"
            messagebox={true}
            okButtonenabled={true}  
         /> 
        )
    }
    

    modalDatenschutz(){

        const body=(
            <div>
               <p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
<p><strong>Benennung der verantwortlichen Stelle</strong></p>
<p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
<p><span id="s3-t-firma">Herbert Dapper</span><br/><span id="s3-t-strasse">Industriestrasse 43</span><br/><span id="s3-t-plz">CH-8212</span> <span id="s3-t-ort">Neuhausen am Rheinfall</span></p><p></p>
<p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>

<p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
<p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

<p><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></p>
<p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>

<p><strong>Recht auf Datenübertragbarkeit</strong></p>
<p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

<p><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></p>
<p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>

<p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
<p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

<p><strong>Kontaktformular</strong></p>
<p>Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</p>
<p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
<p>Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</p>

<p><strong>YouTube</strong></p>
<p>Für Integration und Darstellung von Videoinhalten nutzt unsere Website Plugins von YouTube. Anbieter des Videoportals ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.</p>
<p>Bei Aufruf einer Seite mit integriertem YouTube-Plugin wird eine Verbindung zu den Servern von YouTube hergestellt. YouTube erfährt hierdurch, welche unserer Seiten Sie aufgerufen haben.</p>
<p>YouTube kann Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen, sollten Sie in Ihrem YouTube Konto eingeloggt sein. Durch vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden.</p>
<p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>
<p>Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: <a href="https://www.google.de/intl/de/policies/privacy">https://www.google.de/intl/de/policies/privacy</a>.</p>
        <p><small>Quelle: Datenschutz-Konfigurator von <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">mein-datenschutzbeauftragter.de</a></small></p>


                
                
                
              
    
            </div>
        )
    
        return(
    
            <Modal
                modalid="modalDatenschutzDialog"
                buttonid="modalDatenschutzDialogButton"   
                title="Datenschutzerklärung" 
                body={body} 
                messagebox={true}
                closeButtonTitle="OK"
                modalExtraLarge={true}
            />
        )
    }

   



    modalImpressum(){

        const body=(
            <div>
                <p><span id="s3-t-firma">Herbert Dapper<br/></span><br/>
                <span id="s3-t-strasse">Industriestrasse 43</span><br/>
                <span id="s3-t-plz">CH-8212</span> <span id="s3-t-ort">Neuhausen am Rheinfall / Schweiz</span><br/>   
                <span id="s3-t-email">Email: info@franciscoguitar.ch</span>         
                <span id="s3-t-email">Tel: +41 77 479 2490</span></p>          
    
            </div>
        )
    
        return(
    
            <Modal
                modalid="modalImpressumDialog"
                buttonid="modalImpressumDialogButton"   
                title="Impressum" 
                body={body} 
                messagebox={true}
                closeButtonTitle="OK"
            />
        )
    }
       

    navBar(){

        var flagDE='flag_de.jpg';
        if (this.state.language==='de')	{
            flagDE='flag_de_.jpg'; 

        }

        var flagEN='flag_en.jpg';
        if (this.state.language==='en')	{
            flagEN='flag_en_.jpg';
        }

        var flagES='flag_es.jpg';
        if (this.state.language==='es')	{
            flagES='flag_es_.jpg';
        }

        var flagIT='flag_it.jpg';
        if (this.state.language==='it')	{
            flagIT='flag_it_.jpg';
        }

        var flagFR='flag_fr.jpg';
        if (this.state.language==='fr')	{
            flagFR='flag_fr_.jpg';
        }

        var flagPT='flag_pt.jpg';
        if (this.state.language==='pt')	{
           flagPT='flag_pt_.jpg';
        }

        const _this = this

     
        var src=`/assets/images/${flagDE}`
        var flagImageDE=<a href="#" onClick={()=>this.setState({language:'de'}, function(){_this.toggleNav()})}><img src={src} border={0} /></a>
        src=`/assets/images/${flagEN}`
        var flagImageEN=<a href="#" onClick={()=>this.setState({language:'en'}, function(){_this.toggleNav()})}><img src={src} border={0} /></a>
        src=`/assets/images/${flagES}`
        var flagImageES=<a href="#" onClick={()=>this.setState({language:'es'}, function(){_this.toggleNav()})}><img src={src} border={0} /></a>
        src=`/assets/images/${flagIT}`
        var flagImageIT=<a href="#" onClick={()=>this.setState({language:'it'}, function(){_this.toggleNav()})}><img src={src} border={0} /></a>
        src=`/assets/images/${flagFR}`
        var flagImageFR=<a href="#" onClick={()=>this.setState({language:'fr'}, function(){_this.toggleNav()})}><img src={src} border={0} /></a>
        src=`/assets/images/${flagPT}`
        var flagImagePT=<a href="#" onClick={()=>this.setState({language:'pt'}, function(){_this.toggleNav()})}><img src={src} border={0} /></a>

      
       
   
        return(

            <section  className="menu cid-r8Q0WEje6z noprint" once="menu" id="menu2-4">

                <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
                        <div className="navbar-brand">
                        <span>
                            <a href="#" onClick={()=>this.setState({activePage:'home'})}>
                                <img src="/assets/images/title.png" alt="Francisco" style={{height: '4.6rem'}} />
                            </a>
                        </span>
                        <span className="navbar-caption-wrap">
                        
                        </span>
                    </div>
                    <button className="navbar-toggler" id="toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    <div  className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
                            <li className="nav-item">
                                <a className="nav-link link text-warning display-4" onClick={()=>this.setState({activePage:'about'}, function(){_this.toggleNav()})} href="#">{this.getLanguageString('$STR_0002').toUpperCase()}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link link text-warning display-4" onClick={()=>this.setState({activePage:'music'}, function(){_this.toggleNav()})} href="#">{this.getLanguageString('$STR_0003').toUpperCase()}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link link text-warning display-4" onClick={()=>this.setState({activePage:'gitarrenschule'}, function(){_this.toggleNav()})} href="#">{this.getLanguageString('$STR_0034').toUpperCase()}</a>
                            </li>
                            {this.unterrichtsMenu()}                           

                            {this.anmeldeMenu()}         
                        
                            
                            
                        </ul>
                    
                        {flagImageDE}
                        {flagImageEN}
                        {flagImageES}
                        {flagImageFR}
                        {flagImageIT}
                        {flagImagePT}
                    <div>
                    </div>
                </div>
                </nav>
            </section>
            )
    }

    showModalLoginDoalog(){
   
        if (this.navBarHeight() > 50){
            $('#toggler').click()
        }
        $('#modalLogindialogButton').click()
    }

    modalLogindialog(){

        

        let okButton = <button  className="btn btn-form btn-primary display-4" onClick={this.checkLogin}  >{this.getLanguageString('$STR_0031')}</button>
        if (this.state.email === "" || this.state.passwort==="" ){
            okButton = <button  className="btn btn-form btn-primary display-4" disabled   >{this.getLanguageString('$STR_0031')}</button>
        }

        let form=(
            <div className="form-1 col-md-12 col-sm-12" >                
                {this.state.errortext}
                <div className="row row-sm-offset">
                    <div className="col-md-12 multi-horizontal" data-for="email">
                        <div className="form-group">
                            <input type="email" className="form-control" onChange={(e)=>this.setState({email:e.target.value})} placeholder="Email" data-form-field="Email" required="" id="email-form1-2" value={this.state.email} />
                        </div>
                    </div>
                    <div className="col-md-12 multi-horizontal" data-for="password">
                        <div className="form-group">
                            <input type="password" className="form-control" onChange={(e)=>this.setState({passwort:e.target.value})}  placeholder={this.getLanguageString('$STR_0073')} required="" data-form-field="password" id="password-form1-2"  value={this.state.passwort} />
                        </div>
                    </div>
                </div>
                <span className="input-group-btn">
                    {okButton}
                </span>   
                <div style={{marginTop:'20px'}}>
                <a className="btn btn-primary" onClick={()=>this.setState({activePage:'passwortvergessen'})} href="#">{this.getLanguageString('$STR_0078')}</a>
                </div>    
            </div>
        )

        let content2 =(            
            <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12" >
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5">
                                    {form}
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )


        let body = this.loginContent()
        if (this.state.passwortvergessen){
            body= this.passwortvergessenContent()
        }



    
     
          return (
              <Modal 
                modalid="modalLogindialog"
                buttonid="modalLogindialogButton"   
                title={this.getLanguageString('$STR_0066')} 
                body={body} 
                okButton={okButton}
                showBottomButtons={false}
                />
          )
    }

    aboLoeschen(){
        const array={
            sql:`DELETE FROM frc_unterricht_abos WHERE id=${this.state.activeAbo.id}`
        }

        const _this = this


        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                
                   this.setState({activeAbo:null}, function(){_this.getSchueler()})
                /*   $('.modal').remove();
                    $('.modal-backdrop').remove();
                    $('body').removeClass( "modal-open" );    */  

               
            }
        )
    }

    deleteAboDialog(){

        if (this.state.activeAbo===null) return null

        const msg=`Abonnement ${this.state.activeAbo.thema_de} löschen?`

        return(
            <Modal 
            modalid="modalDeleteAboDialog"
            buttonid="modalDeleteAboDialogDialogButton"   
            title="Abomnnement löschen"
            body={msg} 
            okButtonClick={this.aboLoeschen}
            okButtonTitle="OK"
            closeButtonTitle={this.getLanguageString('$STR_0184')}
            />
        )

    }

    deleteSchuelerDialog(){

        if (this.state.activeSchueler===null) return null

        const msg=`Schüler/in ${this.state.activeSchueler.name} löschen?`

        return(
            <Modal 
            modalid="modalDeleteSchuelerDialog"
            buttonid="modalDeleteSchuelerDialogButton"   
            title="Schüler/in löschen"
            body={msg} 
            okButtonClick={this.schuelerLoeschen}
            okButtonTitle="OK"
            closeButtonTitle={this.getLanguageString('$STR_0184')}
            />
        )
    }

    footer(){
            let year = (new Date()).getFullYear();
            let copyright = `© ${year} FRANCISCOGUITAR.CH`
            let version = require("../../package.json").version
            let datum = require("../../package.json").datum
            let serverversion = null
            if (this.state.server !==null){
                serverversion = this.state.server.version
            }
    

            return(

            <section className="cid-r8R9ZFfxoE noprint" id="footer2-b">
            
                <div className="container text-center">           
                
                    <div className="row justify-content-center">
                        <ul className="foot-menu">                          
                            
                            <li className="foot-menu-item mbr-fonts-style display-7">
                                <a href="#" onClick={()=>$('#modalImpressumDialogButton').click()} className="text-warning">
                                {this.getLanguageString('$STR_0008').toUpperCase()}
                                </a>
                            </li><li className="foot-menu-item mbr-fonts-style display-7">
                                <a href="#" onClick={()=>$('#modalDatenschutzDialogButton').click()} className="text-warning">
                                {this.getLanguageString('$STR_0065').toUpperCase()}
                                </a>
                                <div className="rhombus"></div>
                            </li>
                            <li className="foot-menu-item mbr-fonts-style display-7">
                                <a href="#" onClick={()=>this.setState({activePage:'contact'})} className="text-warning">
                                {this.getLanguageString('$STR_0006').toUpperCase()}
                                </a>
                                <div className="rhombus"></div>
                            </li>
                        </ul>
                    </div>                
                
                    <div className="row">
                       <div  className="text-primary">{copyright} {version}.{datum} {serverversion}</div> 
                       
                    </div>
                
                
                    
                </div>
            </section>

        )
    }

    aboutContent(){



        const images=[

            {
                src:"/assets/images/michaela_.jpg",
                title:this.getLanguageString('$STR_0198'),
                className:'w-full rounded'
            }       ,   

            {
                src:"/assets/images/juanherbert1_.png",
                title:this.getLanguageString('$STR_0064'),
                className:'w-full rounded',   
                
            },
            {
                src:"/assets/images/juanherbert2_.png",
                alt:this.getLanguageString('$STR_0064'),
                className:'w-full rounded',   
            },
            {
                src:"/assets/images/cordoba_.jpg",
                title:this.getLanguageString('$STR_0039'),
                className:'w-full rounded',    
            },
            {
                src:"/assets/images/manolo_.jpg",
                title:this.getLanguageString('$STR_0040'),
                className:'w-full rounded',    
            },
            {
                src:"/assets/images/manolofrancisco_.jpg",
                title:this.getLanguageString('$STR_0041'),
                className:'w-full rounded',   
            },
            {
                src:"/assets/images/manolosign_.jpg",
                title:this.getLanguageString('$STR_0042'),
                className:'w-full rounded',  
            },
            {
                src:"/assets/images/tomatito_.jpg",
                title:this.getLanguageString('$STR_0043'),
                className:'w-full rounded'
            },
            {
                src:"/assets/images/gerardo_.jpg",
                title:this.getLanguageString('$STR_0044'),
                className:'w-full rounded'
            },
            {
                src:"/assets/images/juanronda_.jpg",
                title:this.getLanguageString('$STR_0045'),
                className:'w-full rounded'
            },
            {
                src:"/assets/images/juansign_.jpg",
                title:this.getLanguageString('$STR_0046'),
                className:'w-full rounded'
            },
            {
                src:"/assets/images/cd8_.jpg",
                title:this.getLanguageString('$STR_0055'),
                className:'w-full rounded'
            },
        ]

       const slideshow=(
            <SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto' showThumbnails={true} images={images} captionStyle={{color: "silver"}}>

                   
        </SlideshowLightbox> 
        )


        return(

            <>

            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about.png)'}}>
     
                <div className="container text-center" data-stellar-background-ratio=".5" style={{marginTop:'55px'}}>
                    <div className="row">            
                            
                        <div className="col slider-text  slider-text-two">                        
                            <img src="/assets/images/studio1.jpg" width="450"/>
                        </div>

                        <div className="col slider-text  slider-text-two" style={{backgroundColor:'white', boxShadow: '3px 2px #232124'}}>                        
                            <img src="/assets/images/guitar.png"/>                        
                            <h4 className="mbr-fonts-style mbr-card-title" mbr-theme-style="display-5" mbr-if="showTitle" data-app-selector=".mbr-card-title,.card-img">{this.getLanguageString('$STR_0011')}</h4>
                            <p>{this.getLanguageString('$STR_0014')}{this.getLanguageString('$STR_0015')}</p>
                        </div>
                   
                        <div className="col slider-text  slider-text-two" style={{backgroundColor:'white', boxShadow: '3px 2px #232124'}}>   
                            <img src="/assets/images/bailarina.png" />             
                        
                        <h4 className="mbr-fonts-style mbr-card-title" mbr-theme-style="display-5" mbr-if="showTitle" data-app-selector=".mbr-card-title,.card-img">{this.getLanguageString('$STR_0012')}</h4>

                            <p>{this.getLanguageString('$STR_0016')} {this.getLanguageString('$STR_0017')}</p>
                        </div>
                       

                        <div className="col slider-text  slider-text-two">                        
                            <img src="/assets/images/studio2.jpg" width="450"/>
                        </div>

                    </div>
                </div>        
                <div className="mbr-arrow hidden-sm-down"  style={{marginBottom:'25px'}}>
                    <a href="#next">
                        <i className="mbri-down mbr-iconfont"></i>
                    </a>
                </div>
            
            </section>
            <a name="next"></a>    
            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/davidback.png)'}}>
            
                <div className="container text-center" data-stellar-background-ratio=".5" >
                    <div className="row slider-wrapper">
                        <div className="col-md-12">
                        {slideshow}
                        </div>
                    </div>
                </div>
               
            </section>
            </>
        )
    }

    gitarrenschuleContent(){


        return(
             <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about.png)'}}>
     
                <div className="container text-center" data-stellar-background-ratio=".5" style={{marginTop:'55px'}}>
                    <div className="row">            
                            
                        <div className="col slider-text  slider-text-two">                        
                            <div style={{textAlign:'left', backgroundColor:'white', padding:'10px'}}>
                                <h5>{this.getLanguageString('$STR_0163')}</h5>
                                {this.getLanguageString('$STR_0164')}                   
                                <ul className="nomargin" style={{textAlign:'left'}} >
                                <li>{this.getLanguageString('$STR_0165')}</li>
                                <li>{this.getLanguageString('$STR_0166')}</li>
                                <li>{this.getLanguageString('$STR_0167')}</li> 
                                </ul>

                                <p>{this.getLanguageString('$STR_0168')}</p>
                                <p>{this.getLanguageString('$STR_0169')}</p>
                                <p>{this.getLanguageString('$STR_0170')}</p>  
                            </div>
                        </div>
                         
                        <div className="col slider-text  slider-text-two">                        
                            <div style={{textAlign:'left', backgroundColor:'white', padding:'10px'}}>
                                <h5>{this.getLanguageString('$STR_0179')}</h5>                      
                            
                                <ul style={{textAlign:'left'}}  className="nomargin">
                                <li>{this.getLanguageString('$STR_0171')}</li>
                                <li>{this.getLanguageString('$STR_0172')}</li>
                                <li>{this.getLanguageString('$STR_0173')}</li>
                                <li>{this.getLanguageString('$STR_0174')}</li>
                                <li>{this.getLanguageString('$STR_0175')}</li>
                                <li>{this.getLanguageString('$STR_0176')}</li>
                                </ul>
                                
                                <p>{this.getLanguageString('$STR_0177')}</p>
                                <p>{this.getLanguageString('$STR_0178')}</p>
                            </div>   
                            <p style={{marginTop:'35px'}}><a className="btn btn-form btn-primary display-4" href="#" onClick={this.neueUnterrichtsanfrage}>{this.getLanguageString('$STR_0122')}</a></p>
         
                        </div>
                    </div>
                 
                </div>        
               
               
            </section>
        )

       
    }




    homeContent(){

        let link1 = `https://www.eventpeppers.com/de/goto-profile/epp655787675/iw3`
        let link2 = `https://www.eventpeppers.com/de/profile/epp655787675/img-wid/3/w270/event-idee.jpeg`

        let eventpepperslink=<a href={link1} target="_blank"><img src={link2} alt="Event Idee" title="Francisco bei eventpeppers"  /></a>

        return(
            <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/sevillacalle-1400x467.png)'}}>
                <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12">
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5">
                                            
                                        
                                <div className="col row-item"> 
                                    {eventpepperslink}

                                </div>

                                <div className="col row-item">                       
                                <img src="/assets/images/teacher.png" />                          
                                    <h3 className="styled">{this.getLanguageString('$STR_0034')}</h3>
                                    <div className="separator"></div>
                                    <p>{this.getLanguageString('$STR_0036')}</p>
                                    <a className="btn btn-primary" href="#" onClick={()=>this.setState({activePage:'gitarrenschule'})}>{this.getLanguageString('$STR_0035').toUpperCase()}</a>
                                </div>
                            
                        
                                <div className="col row-item">                        
                                    <img src="/assets/images/bailarina.png" />                          
                                        <h3 className="styled">{this.getLanguageString('$STR_0101')}</h3>
                                        <div className="separator"></div>
                                        <p>{this.getLanguageString('$STR_0022')} {this.getLanguageString('$STR_0023')}</p>
                                        <a className="btn btn-primary" href="#" onClick={()=>this.setState({activePage:'about'})} >{this.getLanguageString('$STR_0024').toUpperCase()}</a>
                                </div>

                                <div className="col row-item">   
                                <img src="/assets/images/concert.png" width="400" height="200"/>                               
                                        <h3 className="styled">{this.getLanguageString('$STR_0099').toUpperCase()}</h3>
                                        <div className="separator"></div>
                                        <p>{this.getLanguageString('$STR_0100')}</p>
                                        <a className="btn btn-primary" href="#"  onClick={()=>this.setState({activePage:'contact'})} >{this.getLanguageString('$STR_0098').toUpperCase()}</a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div> 
                </div>
                
         
            
            </section>
        )
    
    }



    setAbo(abo){
        const _this = this
        this.setState({activeAbo:null}, function(){_this.setState({activeAbo:abo}, function(){_this.getLektionen(abo.id)})})

    }

  


    getContent(){

        var content = <div></div>

        if (this.state.activePage === "home") content = this.homeContent()
        if (this.state.activePage === "about") content = this.aboutContent()
        if (this.state.activePage === "gitarrenschule") content = this.gitarrenschuleContent()
        if (this.state.activePage === "meineAbos"  &&  this.state.activeUser!== null && this.state.activeUser.ist_admin===0)  content= (
            <Schueler
                getString={this.getLanguageString}
                schueler_lektionen={this.state.schueler_lektionen}
                onShowActiveAbo={()=>$('#modalActiveSchuelerAboDialogButton').click()}
                onShowAngebotAbo={()=>$('#modalAngebotSchuelerAboDialogButton').click()}
                neueUnterrichtsanfrage={this.neueUnterrichtsanfrage}
                schuelerAbos={this.state.schuelerAbos}  
                activeSchuelerAbo={this.state.activeSchuelerAbo}
                angebotSchuelerAbo={this.state.angebotSchuelerAbo}   
                
            />
            
        )
        if (this.state.activePage === "account") content= this.accountContent();
        if (this.state.activePage === "passwortvergessen") content= this.passwortvergessenContent();
        if (this.state.activePage === "music") content= this.musicContent();
        if (this.state.activePage === "contact") content= this.contactContent();
       
        if (this.state.activePage === "admin") content= (
            <Admin
                getString={this.getLanguageString}
                schuelerFilter={this.state.schuelerFilter}
                filteredSchueler={this.state.filteredSchueler}
                setSchuelerFilter={this.setSchuelerFilter}
                activeSchueler={this.state.activeSchueler}
                onSchuelerClick={(schueler)=>this.setState({activeSchueler:schueler, activeAbo:null})}
                activeAbo={this.state.activeAbo}
                onAboClick={(abo)=>this.setAbo(abo)}
                language={this.state.language}
                adminPage={this.state.adminPage}
                setAdminPage={(adminPage)=>this.setState({adminPage})}
                gespeichert={this.state.gespeichert}
                changeSchuelerData={(e)=>this.changeSchuelerData(e)}
                nav_abo_modus={this.state.nav_abo_modus}
                setNavAboModus={(modus)=>this.setState({nav_abo_modus:modus})}
                saveSchueler={this.saveSchueler}
                neuerSchueler={this.neuerSchueler}
                changeSchuelerStatus={(status)=>{this.changeSchuelerStatus(status)}}
                deleteSchueler={()=>$('#modalDeleteSchuelerDialogButton').click()}
                kursthemen={this.state.kursthemen}
                setKursthemaid={(value)=>this.setKursthemaid(value)}
                unterrichtsorte={this.state.unterrichtsorte}
                setUnterrichtsort={(value)=>this.setUnterrichtsort(value)}
                setAltersgruppe={(value)=>this.setAltersgruppe(value)}
                setLektionsdauer={(value)=>this.setLektionsdauer(value)}
                changeAbo={(e)=>this.changeAbo(e)}   
                lektionenpreise={this.state.lektionenpreise}     
                setAboStatus={(value)=>this.setAboStatus(value)}      
                saveAbo={(value)=>this.saveAbo(value)} 
                aboLoeschen={()=>$('#modalDeleteAboDialogDialogButton').click()} 
                neuesAbo={this.neuesAbo}
                lektionen={this.state.lektionen}
                editLektion={this.editLektion}
                aboangebotsenden={this.aboangebotsenden}
                this={this}
                
                
            />
        )
        if (this.state.activePage==="unterrichtsanfrage"){
            content = (
                <Unterrichtsanfrage
                getString={this.getLanguageString}
                language={this.state.language}
                kursthemen={this.state.kursthemen}
                activeAbo={this.state.activeAbo}   
                changeAbo={this.changeAbo}   
                setKursthemaid={(value)=>this.setKursthemaid(value)}    
                unterrichtsorte={this.state.unterrichtsorte}
                setUnterrichtsort={(value)=>this.setUnterrichtsort(value)} 
                setAltersgruppe={(value)=>this.setAltersgruppe(value)}
                setLektionsdauer={(value)=>this.setLektionsdauer(value)}
                lektionenpreise={this.state.lektionenpreise}
                setAnzahlstunden = {this.setAnzahlstunden}
                activeSchueler={this.state.activeSchueler}
                changeSchuelerData={this.changeSchuelerData}
                sendeUnterrichtsanfrage={(abo,schueler)=>this.sendeUnterrichtsanfrage(abo,schueler)}

            />
            )
            if (this.activePage === "dankeFuerUnterrichtBestellung"){
                content = (
                    <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12" >
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5">                                    
                           
                            <h3>{this.getLanguageString('$STR_0142')}</h3>
                        <strong>{this.getLanguageString('$STR_0143')}, <br/>Francisco</strong>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
                   
                )
            }
        }
       
            
        return content;
    
    }

    render(){

    
       // if (this.state.server==null) return <div></div>

      
       let version = require("../../package.json").version

        return( 
             <CacheBuster
                currentVersion={version}
                isEnabled={true} //If false, the library is disabled.
                isVerboseMode={false} //If true, the library writes verbose logs to console.
                loadingComponent={<div>Check new version...</div>} //If not pass, nothing appears at the time of new version check.
            >
        
                  <div>             
                    {this.modalLogindialog()} {this.modalImpressum()} {this.modalDatenschutz()} {this.deleteSchuelerDialog()} {this.deleteAboDialog()} {this.modalEditLektion()}
                    {this.modalActiveSchuelerAboDialog()} {this.modalUnterrichtsAnfrageFertig()} {this.modalMessageBoxDialog()} {this.modalAngebotSchuelerAboDialog()}
     
                    {this.navBar()}

                    <div   style={{marginTop:'55px'}}>
                         {this.getContent()}
                    </div>

                    {this.footer()}
                
                </div>
            </CacheBuster>
       

        )
       

    }
}



export default Hauptseite;
